<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
		耗材处置汇总
	 </div>  	
	 <el-form ref="form" :inline="true"  :model="search_key" prop="">
		 <div  style="position: absolute;right:20px;z-index: 999;">
		 	<el-button  type="primary" @click="handleExport">导出全部</el-button> 
		 </div>
	     <el-form-item label="">
	         <el-input v-model="search_key.keyword" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
	     </el-form-item>
	     <el-button type="primary" @click="search()">查询</el-button>
	 </el-form>
	  <el-table
	     :data="tableData" 
	     border>
		<el-table-column
		    prop="location_name"
		    label="所在仓库">
		</el-table-column>
	     <el-table-column
	         prop="mtl_code"
	         label="物料编码">
	     </el-table-column>
	      <el-table-column
	         prop="cate_name"
	         label="物料分类">
	     </el-table-column>
		 <el-table-column
		     prop="name"
		     label="物料名称">
		 </el-table-column>
	     <el-table-column 
			sortable
	         prop="total_amount"
	         label="处置数量">
			 <template slot-scope="scope">
				 <span>{{scope.row.total_amount?scope.row.total_amount:'-'}}</span>
			 </template>
	     </el-table-column>
		 <el-table-column
			sortable
		     prop="total_prices"
		     label="处置金额">
			 <template slot-scope="scope">
			 	 <span>{{scope.row.total_prices?scope.row.total_prices:'-'}}</span>
			 </template>
		 </el-table-column>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
	
 </div>
</template>

<script>
export default {
	 inject:['reload'],
    data() {
        return {
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			search_key:{
				keyword:''
			},
			timeSpan:'',
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){   //获取企业数据
		    this.$api.post('CseReport/disposal',{
				keyword:this.search_key.keyword,
				page:this.currentPage-1,
				limit:this.pagesize, 
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		search(){
			this.currentPage = 1;
			this.getData()
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		     this.getData()
		},	
		handleExport(){
			location.href = '/api/admin/CseReport/exportDisposal'
		}
    },
}
</script>
<style scoped lang="scss">
</style>